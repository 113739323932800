import React from 'react';
import { LinkButton } from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import book from '../../images/book.jpg';
import Weave from '../../components/Weave';
//import OrderByBook from '../../components/OrderByInvoice';

const Book = () => {
  return (
    <section id='book'>
      <div className='container'>
        <Weave />
        <div className='row'>
          <div className='col-auto'>
            <a href="https://carolynstuart.com/" target='_blank'>
              <img className='img-fluid book-cover' src={book} alt="Buy The Leader's Suitcase - Essential Equipment for the Leader's Journey" />
            </a>
            <div style={{ textAlign: 'center' }}>
              <LinkButton className={['btn', 'btn-info', 'me-4', 'mb-2']} href="https://carolynstuart.com/#book" target="_blank">
                Buy the Book <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
              </LinkButton>
            </div>
          </div>

          <div className='col'>
            <h4>The Leader&apos;s Suitcase</h4>
            <p>Essential Equipment for the Leader&apos;s Journey</p>
            <p>Carolyn Stuart</p>

            <p>
              All leaders carry with them a metaphorical suitcase that is packed with the tools and strategies they will need to successfully navigate the leadership journey.
              The Leader&apos;s Suitcase trilogy uses the idea of three packing cells to logically organise the leader&apos;s equipment. </p>

            <p>
              This book explores the first packing cell which contains the tools and strategies we need to set ourselves up for success. It is full of fresh ideas and approaches that leaders can deploy to become the leader they aspire to be and the leader others need them to be.
            </p>

            <p className='mb-3'>
              Drawing on strategies from the field of Positive Psychology, intertwined with sound leadership practices, this book takes leaders on the journey from surviving to thriving. It is a playbook for positive deviance, equipping leaders with the strategies and practices for a positive and thriving future.
            </p>
          </div>
        </div>
        <Weave />
      </div>
    </section>
  )
}

export default Book;
