import React, { useState } from 'react';
import { Input } from './FormControls';
import { Button } from './Button';
import { apiCall } from '../functions';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import css from './thursdaythinking.scss';

async function subscribe(json) {
  return await apiCall("/wp-json/weaving/v1/thursday-thinking", {
    json
  });
}

const ThursdayThinking = ({ children }) => {
  const [pending, isPending] = useState(false);
  const [show, setShow] = useState(false);

  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [email, setEmail] = useState(undefined);

  const submit = (e) => {
    e.preventDefault();
    isPending(true);

    subscribe({
      firstName,
      lastName,
      email
    }).then(_ => {
      [setEmail, setFirstName, setLastName].forEach(fn => {
        fn('');
      });

      toast.success(
        <>
          <p>Thank You!</p>
          <small>You are now subscribed to Thursday Thinking.</small>
        </>
      );

      setShow(false);
    })
      .catch(er => {
        console.error(er.message);
        toast.error(
          <>
            <p>Unable to Subscribe!</p>
            <small>Please try again later.</small>
          </>
        );
      })
      .finally(() => {
        isPending(false);
      })
  }

  return (
    <>
      <a className='thursday-thinking' onClick={() => setShow(true)}>
        {children ?? 'Sign up for our weekly blog post "Thinking Thursday"'}
      </a>

      <Modal show={show} onHide={setShow} centered>
        <form className="modal-body" onSubmit={submit}>
          <h4>Subscribe to Thursday Thinking</h4>
          <Input id="tt:fname"
            label="First Name"
            type="text"
            autoComplete="given-name"
            value={firstName}
            setValue={setFirstName}
            required
          />

          <Input id="tt:lname"
            label="Last Name"
            type="text"
            autoComplete="family-name"
            value={lastName}
            setValue={setLastName}
            required
          />

          <Input id="tt:email"
            label="Email"
            type="email"
            value={email}
            setValue={setEmail}
            required
          />

          <div className='d-grid'>
            <Button className={['btn', 'btn-success']} type="success" pending={pending}>Sign Up</Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ThursdayThinking;