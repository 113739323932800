import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { LinkButton } from '../../components/Button';
import { SmoothScroll } from '../../components/Navbar';
import Carousel from 'react-multi-carousel';

import "react-multi-carousel/lib/styles.css";
import face from '../../images/face.png';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Carolyn = () => {
  const Review = ({author, children}) => {
    return children ? (
      <div className='col'>
        { children }
        { author && <p className='blockquote-footer'>{author}</p> }
      </div>
    ) : null
  }

  return (
    <section id='about'>
      <div className='container'>
        <h2>Carolyn Stuart</h2>
        <p className='sub'>Carolyn founded Weaving Futures to equip people to successfully navigate the tricky waters of change. She inspires leaders to be all
          they have dreamed they could be, and the leader that those around them need to be.</p>

        <div className='row'>
          <div className='profile col mb-3'>
            <img className='img-thumbnail img-fluid d-block mx-auto mt-2' src={face} alt='Portrait of Carolyn Stuart' />

            <LinkButton className={['btn', 'btn-danger-50']} onClick={e => SmoothScroll(e, 'contact')}>Start a Conversation</LinkButton>
          </div>
          <div className='col  mb-5'>
            <p>
              Carolyn is an experienced senior leader who is passionate about equipping others to successfully navigate the complexity of today&apos;s world.
              Carolyn works with organisation to gather people around a common purpose, creating the space to find shared meaning. She empowers leaders to realise their vision.
            </p>

            <p>Carolyn has developed a design thinking framework, Te Whakaahura that wraps a cultural narrative around the journey of change. Organisations use this framework to bring discipline and cultural alignment to their strategic planning.</p>

            <p>
              Recently, Carolyn has been writing a series of books called The Leader&apos;s Suitcase. In these books leaders will find all the tools and strategies they need to
              success navigate their leadership journey. More information about The Leader&apos;s Suitcase can be found at <a href="https://carolynstuart.com" target="_blank">carolynstuart.com</a>.
            </p>
          </div>
          <div className='row'>
            <div className='col-auto'>
              <FontAwesomeIcon fixedWidth icon={faQuoteLeft} />
            </div>
            <div className='col-11'>
              <Carousel
                autoPlay={true}
                autoPlaySpeed={1000 * 15}
                removeArrowOnDeviceType={['mobile', 'tablet', 'desktop']}
                responsive={responsive}//responsive
                infinite={true}
              >
                <Review author='Neil Haywood'>
                  Carolyn has the ability to quickly build positive rapport using an approach that is direct but non-threatening. She is able to ask challenging questions which
                  provoke individuals to reflect on their role in an organisation but at the same time not take the remarks at a personal level.
                </Review>
                <Review author='Adrienne Simpson'>
                  Carolyn is an excellent communicator who can clearly convey messages that are both well-ground in research and thought-provoking.
                </Review>
                <Review author='Dr Julie Mackey'>
                  Carolyn builds strong and meaningful relationships based on respect and trust. She has a passion for supporting leaders, creating safe places where they can be vulnerable and share their leadership stories.
                </Review>
                <Review author='John Wright'>
                  A consistent and strong pattern when working with Carolyn is her ability to shape, clarify and make sense of conversations, linking diverse perspectives into cohesive narratives
                </Review>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Carolyn;
