import React, { useContext, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import { ThemeContext } from "../contexts"

import brand from "../images/brand.png";
import './navbar.scss';

const SmoothScroll = (e, target) => {
  // Stop default behaviour and smooth scroll on Index page only!
  if (window.location.pathname == '/') {
    e.preventDefault();
    let el = document.getElementById(target.replace('#', ''));
    window.scrollTo({
      top: el.getBoundingClientRect().top + window.scrollY - 40,
      behavior: 'smooth'
    })
  }
}

const TopNav  = () => {
  const [ expand, setExpanded ] = useState(false);
  const themeContext = useContext(ThemeContext);

  const A = ({children, href}) => <Nav.Link
    href={((window.location.pathname == "/" ? "#" : "/#")  + href)}
    onClick={_ => setExpanded(false)}
  >
    {children}
  </Nav.Link>

  return (
    // Add classes for haswpadminbar
    <Navbar id='spy' expand="lg" fixed='top' expanded={expand} className={themeContext.isWpAdmin ? 'haswpadminbar' : null}>
      <div className="container-fluid">
        <Navbar.Brand href={`/#book`}  onClick={e => SmoothScroll(e, `${urlPrefix}book`)}>
          <img src={brand} alt="Order book The Leader's Suitcase"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="sitenav" onClick={() => setExpanded(!expand)} />
        <Navbar.Collapse id='sitenav'>
          <Nav className='ms-auto'>
            <A href='top'>Home</A>
            <A href='about'>About</A>
            <A href='what-we-do'>What We Do</A>
            <A href='book'>Book</A>
            <A href='blog'>Blog</A>
            <A href='contact'>Contact</A>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default TopNav;
export { SmoothScroll };
