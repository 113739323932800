import React from 'react';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import logoSmall from '../images/logo_sm.png';

const Footer = () => {
  return (
    <footer className='row'>
      <div className='col-sm-12 col-lg-6 col-xl-3'>
        <img src={logoSmall} alt='Weaving Futures logo' />
      </div>

      <div className='col-sm-12 col-lg-6 col-xl-3'>
        <a href="tel:+64274349865">(027) 434 9865</a>
      </div>

      <div className='col-sm-12 col-lg-6 col-xl-3'>
        <a href="mailto:carolyn.stuart@weavingfutures.nz">carolyn.stuart@weavingfutures.nz</a>
      </div>

      <div className='col-sm-12 col-lg-6 col-xl-3 social'>
        <a href='https://www.facebook.com/weavingfutures/' target='_blank' rel='noopener'>
          <FontAwesomeIcon fixedWidth icon={faFacebook} />
        </a>

        <a href='https://www.instagram.com/weavingfuturesltd/' target='_blank' rel='noopener'>
          <FontAwesomeIcon fixedWidth icon={faInstagram} />
        </a>

        <a href='https://www.linkedin.com/company/weaving-futures/' target='_blank' rel='noopener'>
          <FontAwesomeIcon fixedWidth icon={faLinkedinIn} />
        </a>

        <a href='https://twitter.com/FuturesNZ' target='_blank' rel='noopener'>
          <FontAwesomeIcon fixedWidth icon={faXTwitter} />
        </a>
      </div>
    </footer>     
  )
}

export default Footer;