import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './productcard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

const ProductCard = ({ children, description, id, src, title }) => {
  const [show, setShow] = useState(false);

  return (
    <div className='product'>
      <img className='img-fluid koru' src={src} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p>

      <a onClick={() => setShow(true)}>
        Learn More <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
      </a>

      <Modal id={id} show={show} onHide={setShow} dialogClassName='wv' size='xl' centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
              <img src={src} style={{ maxHeight: '50px', marginRight: '20px' }} />
              {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    </div>
  )
}

ProductCard.prototype = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default ProductCard;
