import React from 'react';
import BlogPreviewCard from '../../components/BlogPreviewCard';
import { useApi } from '../../functions';
import { LinkButton } from '../../components/Button';

const LatestPosts = () => {
  const [ posts ] = useApi('/wp-json/weaving/v1/posts');

  return (
    <section id='blog'>
      <div className='container'>
        <h2>Latest Posts</h2>

        <div className='d-block text-center'>
          <LinkButton className={['btn', 'btn-info', 'my-4']} href='/blog'>
            View All Posts
          </LinkButton>
        </div>

        <div className='row'>
          { posts?.slice(0, 3)?.map((post, key) => {
            return (
              <div className='col-sm-12 col-lg-4' key={key}>
                <BlogPreviewCard {...post} key={key} />
              </div>
            )
          }) ?? <p className='text-center'>No posts!</p> }
        </div>
      </div>
    </section>
  )
}

export default LatestPosts;
