import React from 'react';
import logo from "../../images/logo.png";
import { LinkButton } from "../../components/Button";
import { SmoothScroll } from '../../components/Navbar';

const Home = () => {
  return (
    <section id='top' style={{ backgroundImage: 'url(/wp-content/themes/weaving-futures/dist/static/landing.png)'}}>
      <div className='container h-100'>
        <div className='row h-100 justify-content-center align-items-center'>
          <div className='col-12 text-center'>
            <img className='img-fluid d-block ms-auto me-auto mt-4 pb-4' src={logo} loading='lazy' alt='Weaving Futures' />
            <LinkButton type='a' className={['btn', 'btn-info-50', 'btn-lg', 'm-2']} onClick={e => SmoothScroll(e, 'about')}>About</LinkButton>
            <LinkButton type='a' className={['btn', 'btn-danger-50', 'btn-lg', 'm-2']} onClick={e => SmoothScroll(e, 'what-we-do')}>What We Do</LinkButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home;
