import { useCallback, useEffect, useState } from "react";

async function apiCall(path, { json, ...options }) {
  var requestOptions = {
    ...options,
  };

  if (!requestOptions.headers) {
    requestOptions.headers = {};
  }

  if (json) {
    if (!requestOptions.method) {
      requestOptions.method = "POST";
    }
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(json);
  }

  const response = await fetch(path, requestOptions);

  var decoded;
  if ((response.headers.get("Content-Type") || "").match(/json/)) {
    decoded = await response.json();
  } else {
    decoded = await response.text();
  }

  if (response.status >= 400) {
    throw decoded;
  }
  return decoded;
}

const relativeDate = (date) => {
  let d = new Date(Date.parse(date));
  
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

const useApi = (path, options = {}) => {
  const [data, setData] = useState(null);

  const refreshFunction = useCallback(() => {
    if (!path) return;

    apiCall(path, options).then(setData, (err) => {
      setData(null);
      throw err;
    });
  }, [ path]);

  useEffect(() => {
    setData(null);
    refreshFunction();
  }, [refreshFunction]);

  return [data, refreshFunction];
}

export { apiCall, relativeDate, useApi };