import React, { useEffect, useState } from 'react';
import { ThemeContext } from "./contexts";
import { apiCall } from './functions';
import { ToastContainer } from 'react-toastify';
import Footer from './components/Footer';
import TopNav  from './components/Navbar';
import 'react-toastify/dist/ReactToastify.css';

async function WhoAmI(nonce) {
  return await apiCall(`/wp-json/weaving/v1/whoami`, {
    method: 'GET',
    headers: {
      'X-WP-Nonce': nonce
    }
  });
}

const Wrapper = ({ children }) => {
  const [ whoami, setWhoAmI ] = useState(undefined);
  const [ nonce ] = useState(() => {
    return document.head.querySelector("meta[name='csrf']")?.content
  })

  useEffect(() => { WhoAmI(nonce).then(setWhoAmI) }, [])

  return (
    <React.StrictMode>
      <ThemeContext.Provider value={{
        isWpAdmin: !!document.getElementById('wpadminbar'),
        baseUrl: document.querySelector('meta[name="assets"]')?.content,
        nonce,
        whoami
      }}>
        <div id='content'>
          <TopNav  />
          {children}
        </div>
        <Footer />
      </ThemeContext.Provider>
      <ToastContainer autoClose={10000} />
    </React.StrictMode>
  )
}

export default Wrapper;
