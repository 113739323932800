import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Tooltip, Toast, Popover } from 'bootstrap';
import Wrapper from '../Wrapper';

import Book from './pages/Book';
import Carolyn from './pages/Carolyn';
import Contact from './pages/Contact';
import Home from './pages/Home';
import LatestPosts from './pages/LatestPosts';
import WhatWeDo from './pages/WhatWeDo';

import './index.scss';

const App = () => {
  useEffect(() => {
    let hash = window.location?.hash;
    
    // Get the URL hash if avaliable; else exit function    
    if (!hash) return;
    
    let target = document.getElementById(hash.substring(1));
    if (target) {
      window.history.replaceState({}, document.title, '/');
      target.scrollIntoView({  behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <Wrapper>
      <Home />
      <Carolyn />
      <WhatWeDo />
      <Book />
      <LatestPosts />
      <Contact />
    </Wrapper>
  )
}

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);
root.render(<App />);
