import React from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Button = ({ id, className = [ 'btn', 'btn-wv-primary' ], children, disabled = false, pending = false, type = "button" }) => {
  return (
    <button
      id={id}  
      type={type}        
      className={className.join(' ')}
      disabled={disabled || pending}      
    >
      { !pending ? children : (
        <FontAwesomeIcon icon={faSpinner} spin />
      )}
    </button>
  )
}

const LinkButton = ({id, className = [ 'btn', 'btn-wv-primary' ], children, disabled = false, href, pending = false, target = '_blank', onClick = null}) => {
  return (
    <a
      id={id}  
      href={href}      
      className={className.join(' ')}
      disabled={disabled || pending}    
      target={target}  
      onClick={onClick}
    >
      { !pending ? children : (
        <FontAwesomeIcon icon={faSpinner} spin />
      )}
    </a>
  )
}

[ Button, LinkButton].forEach((el) => {
  el.propTypes = {
    className: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    pending: PropTypes.bool
  }
})

export { Button, LinkButton };
