import React from 'react';
import { LinkButton } from '../../components/Button';
import ProductCard from '../../components/ProductCard';
import koru_blue from '../../images/Koru Blue.png';
import koru_lb from '../../images/Koru Light Blue.png';
import koru_g from '../../images/Koru Green.png';
import coaching from '../../images/coaching.png';
import framework from '../../images/Te-Whakaahua-Framework.png';
import mirrors from '../../images/mirrors.png';
import workshop from '../../images/workshop.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import book from '../../images/book.jpg';

const WhatWeDo = () => {
  return (
    <section id='what-we-do'>
      <div className='container'>
        <h2>What We Do</h2>
        <p className='sub'>We work with individuals and organisations to help them design exciting and compelling futures. We start from where people are at, helping them
          to weave together the mahi from the past with the reality of their present so with boldness they can step into their future.</p>

        <div className='row'>
          <div className='col-md-12 col-lg-5 col-xl-4 mb-3'>
            <ProductCard
              id='leaders-suitcase'
              src={koru_blue}
              title="The Leader's Suitcase"
              description="Essential tools and equipment with which to successfully navigate the leadership journey."
            >
              <div className='row'>
                <div className='col'>
                  <h5 className='text-center'>Setting Yourself up for Success<br />Book and Masterclass</h5>

                  <p>The first person everybody leads is themselves.</p>
                  <p>In this, the first book and Masterclass from  The Leader&apos;s Suitcase Trilogy, author Carolyn Stuart weaves strategies from the field of positive psychology with sound leadership practices that help us thrive and not just survive as leaders.</p>
                  <p>This book and the related Masterclass are all about setting ourselves up for success. They are full of fresh ideas and approaches we can deploy to become the leader we aspire to be and the leader others need us to be.</p>

                  <ul className='quotes'>
                    <li>This book and masterclass have given me positive and proactive steps I can take. It gave me loads of ideas that I have already started putting into action, making me feel much more fulfilled at home and work.</li>
                  </ul>

                  <div className='text-center'>
                    <LinkButton className={['btn', 'btn-info', 'me-4', 'mb-2']} href="https://carolynstuart.com/#book" target="_blank">
                      Buy the Book <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
                    </LinkButton>

                    <LinkButton className={['btn', 'btn-primary', 'me-4', 'mb-2']} href="https://carolynstuart.com/Masterclass/Setting-Yourself-up-for-Success" target="_blank">
                      Masterclass Info <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
                    </LinkButton>
                  </div>
                </div>

                <div className='col-auto'>
                  <a href="https://carolynstuart.com/" target='_blank'>
                    <img className='img-fluid book-cover' src={book} alt="Buy The Leader's Suitcase - Essential Equipment for the Leader's Journey" />
                  </a>
                </div>
              </div>
            </ProductCard>
          </div>

          <div className='col-md-12 col-lg-5 col-xl-4 mb-3'>
            <ProductCard
              id='design-thinking'
              src={koru_g}
              title="Design Thinking"
              description="Designing change journeys that set everyone up for success."
            >
              <div className='row'>
                <div className='col-12'>
                  <p>We know that you want people to engage positively and be enthusiastic about change and that you want to create a future that everyone will love. That is why we use Design Thinking, a methodology that puts people not problems at the centre, to underpin our work. We want you to have innovative solutions that work for your people.</p>

                  <div className='text-center'>
                    {/* <LinkButton className={['btn', 'btn-info', 'me-4', 'mb-2']} href="https://carolynstuart.com/#book" target="_blank">
                      Buy the Book <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
                    </LinkButton> */}

                    <LinkButton className={['btn', 'btn-success', 'me-4', 'mb-2']} href="https://carolynstuart.com/Masterclass/Design-Thinking" target="_blank">
                      Masterclass Info <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
                    </LinkButton>
                  </div>

                  <h5>Design Thinking:</h5>
                </div>

                <div className='col'>
                  <ul>
                    <li>Provides a <span className='highlight'>disciplined</span> approach to innovation</li>
                    <li>Is a <span className='highlight'>human-centred</span> framework for problem-finding and problem solving</li>
                    <li>Starts by <span className='highlight'>gaining empathy</span> with the people at the centre of change</li>
                    <li>Is a process through which you can <span className='highlight'>hear all voices</span> in your community</li>
                    <li><span className='highlight'>Balances</span>  the needs of users with possibilities derived from professional knowledge and experience</li>
                    <li>Uses <span className='highlight'>prototyping</span> to test and refine ideas</li>
                    <li><span className='highlight'>Tests assumptions</span> that underpin solutions ensuring that any solution you move ahead with will work</li>
                    <li>Gives change <span className='highlight'>momentum</span></li>
                  </ul>
                </div>

                <div className='col-auto d-flex'  style={{ alignItems: 'center' }}>
                  <img src={workshop} />
                </div>
              </div>

              <h5>Te Whakaahua Framework</h5>
              <p>Weaving Futures&apos; approach to design thinking is different because Te Whakaahua Framework brings to life the cultural narrative around change. We use Te Whakaahua Framework to apply tools and strategies that have traditionally sat in the IT world to business, education, and social endeavours. Our deep educational roots ensure maximum engagement and learning for all our clients.</p>

              <img src={framework} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '90%' }} />
              <p>We work with leaders to co-design solutions that fit their needs - everything from Introduction to Design Thinking workshops to solving unique complex challenges. Change journeys are too important to be left to chance.</p>
            </ProductCard>
          </div>

          <div className='col-md-12 col-lg-5 col-xl-4 mb-3'>
            <ProductCard
              id='wayfinding'
              src={koru_lb}
              title="Wayfinding"
              description="Setting leaders up to be the best they can be."
            >
              {/* Thursday Thinking */}
              <h5>Thursday Thinking</h5>
              <p>Carolyn&apos;s blog about leadership and life is delivered to hundreds of inboxes at 6:00 am every Thursday morning.</p>
              <ul className='quotes'>
                <li>Yet another goodie. How do you know that's exactly what I need to hear? You're magic!</li>
                <li>Just for those keeping score... You're still batting 100% on delivering exactly what I'm needing.</li>
                <li>This is brilliant! Saving it to my leadership notes.</li>
              </ul>
              <a href='https://weavingfutures.us20.list-manage.com/subscribe?u=6fc8ae24f7566c26a5e2ba386&id=84cd24576b' target='_blank'>Sign Up for Thursday Thinking</a>

              {/* The Mirror of Leadership */}
              <div id="mirror-of-leadership" className='row mt-4'>
                <div className='col-lg-auto col-md-12 d-sm-block'>
                  <img id='mirrors' className='img-fluid' src={mirrors} style={{ maxWidth: '400px' }} />
                </div>

                <div className='col'>
                  <h4>The Mirror of Leadership</h4>
                  <h5>Reflections of our best selves</h5>
                  <p>As leaders, we look through two mirrors. One reflects how we see ourselves, the second reflects how others see us. The Mirror of Leadership aligns the way we perceive ourselves with how others see us.</p>
                  <p className='text-center' style={{ fontStyle: "italic" }}>
                    "The Mirror of Leadership Masterclass enabled me to discover how others saw me at my best. It helped me to understand how to use these insights to become the leader others needed me to be."
                  </p>

                  <div className='text-center'>
                    {/* <LinkButton className={['btn', 'btn-info', 'me-4', 'mb-2']} href="https://carolynstuart.com/#book" target="_blank">
                      Buy the Book <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
                    </LinkButton> */}

                    <LinkButton className={['btn', 'btn-primary-50', 'me-4', 'mb-2']} href="https://carolynstuart.com/Masterclass/Mirror-of-Leadership" target="_blank">
                      Masterclass Info <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} />
                    </LinkButton>
                  </div>
                </div>
              </div>

              {/* Workshops */}
              <div id="workshops" className='row mt-4'>
                <h5>Workshops</h5>

                <div className='col'>
                  <p>Workshops are tailored to the needs of organisations. Whether it is learning about Design Thinking, resetting your strategy, or a deep dive into how we can use our strengths to function more effectively as a team, Weaving Futures’ workshops are highly engaging and participatory.</p>

                  <ul className='quotes'>
                    <li>The openness of the facilitator about her own journey created a safe workshop environment as did the participants fully engaging in the learning.</li>
                  </ul>
                </div>

                <div className='col-lg-auto col-md-12 d-sm-block'>
                  <img id='self-review-checklist' src={coaching} />
                </div>
              </div>

              {/* Keynotes */}
              <h5>Keynotes</h5>
              <p>If you are looking for a speaker who can is passionate about equipping others to thrive and not just survive then look no further! We deliver keynotes that provide fresh insights into setting yourself up for success, setting others up for success and setting your organisation up for success. Leaders come away with new ideas and materials for their Leader's Suitcase.</p>

            </ProductCard>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo;
