import React from 'react';
import weave from '../images/weave.png';

const Weave = () => {
    return <img className='img-fluid weave-hr' src={weave} alt='weave' style={{
        marginLeft: '0px',
        marginRight: '0px'
    }}/>
}

export default Weave;