import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const FormGroup = ({ children }) => {
  return (
    <div className='form-floating mb-3'>
      {children}
    </div>
  )
}

const Label = ({ id, children, required }) => {
  return <label htmlFor={id} className={required ? 'required' : null}>{children}</label>
}

const Checkbox = ({ checked, label, setValue }) => {
  return (
    <div className='mb-2'>
      <label>
        <input type="checkbox"  checked={checked} onClick={() => setValue(!checked)} />
        {label}
      </label>
    </div>
  );
}

const Input = ({ id, autoComplete, label, helpText, max = null, min = null, placeholder, type, readOnly, required, value = '', setValue }) => {
  return (
    <FormGroup>
      <input
        id={id}
        type={type}
        className="form-control"
        autoComplete={autoComplete}
        placeholder={placeholder ?? label}
        min={min}
        max={max}
        readOnly={readOnly}
        required={required}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Label htmlFor={id} required={required}>{label}</Label>
      {helpText && (
        <small className='text-muted' style={{ fontSize: 'small' }}>{helpText}</small>
      )}
    </FormGroup>
  )
}

const Textarea = ({ id, label, autoComplete, placeholder, minLength, maxLength, readOnly, required, style, value = '', setValue }) => {
  const charCount = {}

  if (minLength && minLength > value.length) {
    let count = minLength - value.length;
    charCount.min = `${count} more character${count > 1 ? 's' : ''}.`;
  }
  else if (maxLength) {
    charCount.max = `${value.length} out of ${maxLength}.`;
  }

  return (
    <FormGroup>
      <textarea
        id={id}
        autoComplete={autoComplete}
        className="form-control"
        placeholder={placeholder ?? label}
        readOnly={readOnly}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        style={style}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Label htmlFor={id} required={required}>{label}</Label>
      {(charCount.min ?? charCount.max) && (
        <small>{charCount.min ?? charCount.max}</small>
      )}
    </FormGroup>
  )
}

const Search = ({ defaultValue = '', placeholder, onSubmit }) => {
  const [value, setValue] = useState(undefined);

  const submit = (e) => {
    if (e) e.preventDefault();

    if (value == undefined) {
      console.warn(`Search value is ${value}`);
      return;
    }

    onSubmit(value != '' ? value : null);
  }

  return (
    <form className='search-box' onSubmit={submit}>
      {/* <FontAwesomeIcon fixedWidth icon={faSearch} onClick={submit} /> */}
      <button>Search</button>
      <input type='text'
        className='form-control'
        autoComplete='none'
        placeholder={placeholder}
        value={value ?? defaultValue}
        onChange={((e) => setValue(e.target.value))}
      />
    </form>
  )
}

export { Checkbox, Input, Textarea, Search };
