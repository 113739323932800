import React, { useContext } from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { LinkButton } from './Button';
import './blogpreviewcard.scss';
import koru from '../images/Koru Green.png';

const BlogPreviewCard = ({ id, description, image, tags = [], title, url }) => {

  if (!tags) {
    tags = [];
  }

  if (image == '') {
    image = koru;
  }

  return (
    <div className='post-preview'>
      <div className='image'>
        <a href={`/?post-type=post&p=${id}`} target='_blank'>
          <img src={image} alt={title} loading='lazy' />
        </a>
        <ul className='tags'>
          { tags?.map((tag, key) => {
            return (
              <li key={key}>
                <a href={`/blog?tag=${tag.slug}`} target='_blank'>
                  <FontAwesomeIcon fixedWidth icon={faTag} />
                  <span>{tag.name}</span>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className='content'>
        <h3>{title}</h3>
        <p>{description}</p>

        <ul className='tags'>
          <li>
            <FontAwesomeIcon fixedWidth icon={faTag} />
          </li>
          {tags?.map((tag, key) => (
            <li ley={key}>
              <a href={`/blog?tag=${tag.slug}`} target='_blank'>
                {tag.name}
              </a>
            </li>
          ))}
        </ul>

        <LinkButton className={['btn', 'btn-danger-50', 'view']} href={url}>
          Read More
        </LinkButton>
      </div>
    </div>
  )
}

BlogPreviewCard.defaultProps = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default BlogPreviewCard;
