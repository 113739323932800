import React, { useState } from 'react';
import { apiCall } from '../../functions';
import { toast } from 'react-toastify';

import { Button } from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Input, Textarea } from '../../components/FormControls';
import image from '../../images/logo_sm.png';
import ThursdayThinking from '../../components/ThursdayThinking';

const Contact = () => {
  const [ pending, isPending ] = useState(false);
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');

  async function sendEmail() {
    return await apiCall(`/wp-json/weaving/v1/contact`, {
      method: 'POST',
      json: {
        Name: name,
        Email: email,
        Message: message
      }
    });
  }

  const submit = (e) => {
    e.preventDefault();
    isPending(true);

    sendEmail().then(_ => {
      [ setName, setEmail, setMessage ].forEach(fn => {
        fn('');
      });

      toast.success(
        <>
          <p>Thank You!</p>
          <small>You email has been sent.</small>
        </>
      );
    })
    .catch(er => {
      console.error(er.message);
      toast.error(
        <>
          <p>Something Went Wrong!</p>
          <small>Please try again later or send us a message on social media.</small>
        </>
      );
    })
    .finally(() => isPending(false));
  }

  return (
    <section id='contact'>
      <div className='container'>
        <h2>Start a Conversation</h2>

        <div className='row'>
          <div className='col-lg-12 col-xl-6 my-3'>
            <div className='call-to-action'>
              <p className='pb-2'>Are you ready to start thriving as a leader?</p>
              <p className='pb-2'>Are you interested in exploring the benefits of a strengths-based approach to your leadership?</p>
              <p className='pb-2'>Are you keen to discover how design thinking can set your organisation up for success?</p>
              <img className='img-fluid d-block mx-auto' src={image} alt='Weaving Futures Logo' />
            </div>
          </div>

          <form className='col-lg-12 col-xl-6 my-3' onSubmit={submit}>
            <Input id="contact:name"
              label="Your Name"
              type="text"
              value={name}
              autoComplete="name"
              setValue={setName}
              required
            />
            <Input id="contact:email"
              label="Your Email"
              type="email"
              autoComplete="email"
              value={email}
              setValue={setEmail}
              required
            />
            <Textarea id="contact:message"
              label="Your Message"
              minLength={50}
              maxLength={500}
              style={{ minHeight: '190px' }}
              value={message}
              setValue={setMessage}
              required
            />

            <Button className={['btn', 'btn-danger-50']} disabled={pending} type='submit'>
              Send Email <FontAwesomeIcon fixedWidth icon={faEnvelope} />
            </Button>
          </form>
          <div className='text-center my-4'>
            <ThursdayThinking />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;
